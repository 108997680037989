import request from '@/utils/request'
import qs from 'qs';

/**
 * 查询网站信息
 * @returns 
 */
//读取轮播图
export function getBanner(data) {
  return request({
    url: '/index/banner',
    method: 'post',
    data:qs.stringify(data)
  })
}

//读取菜单
export function getMenus(data) {
  return request({
    url: '/index/menus',
    method: 'post',
    data:qs.stringify(data)
  })
}

//读取广告
export function getAdvertSpec(data) {
  return request({
    url: '/index/advertSpec',
    method: 'post',
    data:qs.stringify(data)
  })
}

//推荐课程
export function getAdvertCourse(data) {
  return request({
    url: '/index/advertCourse',
    method: 'post',
    data:qs.stringify(data)
  })
}

//读取广告qq
export function getAdvertQQ(data) {
  return request({
    url: '/index/qq',
    method: 'post',
    data:qs.stringify(data)
  })
}


//推荐题库
export function testBank(data) {
  return request({
    url:'/exam/pushTestGood',
    method: 'post',
    data:qs.stringify(data)
  })
}

//推荐讲师
export function getTeacher(data) {
  return request({
    url: '/index/teacher',
    method: 'post',
    data:qs.stringify(data)
  })
}


//读取广告app
export function getAdvertApp(data) {
  return request({
    url: '/index/app',
    method: 'post',
    data:qs.stringify(data)
  })
}

//根据二级资格证id取得下面的套餐和班级
//product/getGradeAndCombo
export function getGradeAndComboBySpecId(data) {
  return request({
    url: '/product/getGradeAndCombo',
    method: 'post',
    data: qs.stringify(data)
  })
}

//专题查询
//product/spec
export function getSpecialList(data) {
  return request({
    url: '/product/spec',
    method: 'post',
    data: qs.stringify(data)
  })
}

//题库查询
export function getExamList(data) {
  return request({
    url: '/index/testbank',
    method: 'post',
    data: qs.stringify(data)
  })
}
