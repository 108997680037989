<template>
  <div>
	<!-- 头部导航 -->
	<el-affix>
		<nav-bar></nav-bar>
	</el-affix>
	<!-- 工具栏 -->
	<tool-box></tool-box>
	<!-- 导航菜单 -->
	
	<!-- 广告轮播加课程类型菜单 -->
	<div class='swiper-wrap'>
		<div class='swiper'>
			<swiper
				:slides-per-view="1"
				:space-between="0"
				navigation
				:autoplay="{delay:3000,disableOnInteraction:false}"
				loop
				:pagination="{clickable:true}"
				>
				<swiper-slide
					@click='adInfo(item)' 
					v-for='(item,index) in bannerList' 
					:key='index'>
					<li :title="item.title"
						:style="{backgroundImage:'url(\'' + item.thumb + '\')',
						backgroundRepeat:'no-repeat',
						backgroundPosition:'center center',
						height:'360px',
						width:screenWidth + 'px',
						cursor:'pointer'}">
					</li>
				</swiper-slide>
			</swiper>
		</div>
	</div>
	
	<!-- 课程内容 -->
	<div class="course-container">
		<div v-for="spec in menusList" :key="spec.id">
			<div class="special-divider">
				<el-divider>
					<div class="special-container-title">
							<svg-icon icon-class="Blocks_Images"></svg-icon>
							<span>{{spec.title}}</span>
							<svg-icon icon-class="Blocks_Images"></svg-icon>
					</div>
				</el-divider>
			</div>
			<div class="special-container" >
				<div class="special-title">
					<h3>
						{{spec.title}}
					</h3>
					<ul>
						<li v-for="subSpec in spec.children" :key="subSpec.id">
							<a href="#" @click="toSpecialPage(subSpec)">{{subSpec.title}}</a>
						</li>
					</ul>
				</div>
				<div class="special-cover-container">
					<div class="special-cover" :style="{backgroundImage:'url(' + spec.thumb + ')'}">
					</div>
				</div>
				<div class="special-combo-list">
					<el-row :gutter="10">
						<el-col :span="8" v-for="(combo,index) in spec.combos.slice(0,6)" :key="combo.id">
							<div class="combo-container" :class="index > 2 ? 'margin-top-20' : ''" @click="toCourseDetail(combo)">
								<div class="combo-cover">
									<img :src="combo.thumb" alt="">
								</div>
								<div class="combo-title">
									{{combo.title}}
								</div>
								<div class="combo-additional">
									<div class="combo-price">
										<span style="font-size:18px;">¥</span>
										{{combo.sale_price}}
									</div>
									<div class="buy-combo">
										<el-button size="mini" type="text">
											加入学习
										</el-button>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
	<!-- 分割线 -->
	<div class="special-divider">
		<el-divider>
			<div class="special-container-title">
					<svg-icon icon-class="Blocks_Images"></svg-icon>
					<span>密押试卷</span>
					<svg-icon icon-class="Blocks_Images"></svg-icon>
			</div>
		</el-divider>
	</div>
	<!-- 题库 -->
	<div class="exam-container">
		<el-row :gutter="20">
			<el-col :span="4" v-for="exam in exams.slice(0,12)" :key="exam.id">
				<div class="exam-card" @click="toExamDetail(exam)">
					<img :src="exam.thumb" alt="">
					<div class="exam-title">
						{{exam.name}}
					</div>
					<div class="exam-price">
						<span>
							¥{{exam.sale_price}}
						</span>
						<el-button type="text" size="mini">加入学习</el-button> 
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<!-- footer -->
	<web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import SwiperCore, { 
		Navigation, 
		Pagination, 
		Scrollbar, 
		A11y, 
		EffectFade, 
		Autoplay } from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);	

import NavBar from '@/components/page/NavBar'
import ToolBox from '@/components/toolbox'
import WebFooter from '@/components/page/WebFooter'

import {getSessionStorage,setSessionStorage} from '@/utils/auth'
import {mapState,mapMutations} from 'vuex';
import {randSort} from '@/utils/index'

import {
	getBanner,
	getMenus,
	getProSpec,
	getComboBySpec,
	getComboClass,
	getGradeAndComboBySpecId,
	getSpecialList,
	getExamList
} from '@/api/index4'

export default {
  data() {
    return {
			bannerList:[],
			menusList:[],
			levelOneSpecialList:[],
			exams:[]
    }
  },
  components: {
    NavBar,
    ToolBox,
		Swiper,
		SwiperSlide,
		WebFooter,
  },
  computed:{
    ...mapState(['token']),
  },
  created(){
  	this.findBanner()
		this.findMenusList()
		this.findExamList()
		this.registerWindowResize()
  },
  methods:{
		findBanner(){
			getBanner().then(res=>{//获取轮播图
				if(res.code==1){
					this.bannerList = res.data;
				}
			});
		},
		findExamList(){
			getExamList().then(
				res => {
					if(res.code == 1){
						this.exams = res.data
						this.exams = randSort(this.exams)
					}
				}
			)
		},
		findMenusList(){
			getSpecialList().then(res=>{//获取导航菜单
				if(res.code==1){
					this.menusList = res.data;
					//console.log(this.menusList)
					//发起请求，请求每一个一级专题，获取其下所有套餐
					this.menusList.map((item,index) => {
						//继续循环二级专题，然后将取得的套餐放入每个item的combo下
						item.combos = [];
						item.children.map((sub,sIndex) => {
							//将每一个资格证下的前两个放入combos
							getGradeAndComboBySpecId({
								spec_id:sub.id
							}).then(
								res => {
									if(res.code == 1){
										let combos = item.combos;
										
										if(res.data.combo.length >= 3){
											res.data.combo[0].spec_id = sub.id
											res.data.combo[1].spec_id = sub.id
											res.data.combo[2].spec_id = sub.id
											combos.push(res.data.combo[0]);
											combos.push(res.data.combo[1]);
											combos.push(res.data.combo[2]);
										}else if(res.data.combo.length >= 2){
											res.data.combo[0].spec_id = sub.id
											res.data.combo[1].spec_id = sub.id
											combos.push(res.data.combo[0]);
											combos.push(res.data.combo[1]);
										}else if(res.data.combo.length == 1){
											res.data.combo[0].spec_id = sub.id
											combos.push(res.data.combo[0]);
										}
										item.combos = combos;
									}
								}
							)
						})
					})
				}
			});
		},
		adInfo(list){//轮播图广告跳转
			if(list.type == 1){//海报
			
			}
			if(list.type == 2){//课程列表
				let idArr = list.urlId.split(",");
				this.$router.push({
					path:'/course/' + idArr[0] + '/'+idArr[1]
				});
			}
		},
		toSpecialPage(item){
			this.$router.push('/special/' + item.id)
		},
		registerWindowResize(){
			this.screenWidth = document.body.clientWidth;
			this.screenHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					if(document.body.clientWidth > 1920){
						this.screenWidth = 1920
					}else if(document.body.clientWidth < 1200){
						this.screenWidth = 1200
					}else{
						this.screenWidth = document.body.clientWidth;
					}
					this.screenHeight = document.body.clientHeight;
				})();
			};
		},
		toCourseDetail(data){
			this.$router.push('/coursedetail/' + data.spec_id + '/' + data.comboclass_id + '/' + data.id );
		},
		toExamDetail(data){
      setSessionStorage("currentTestGood", data)
      this.$router.push('/secretdetail')
    },
  }
}
</script>

<style scoped lang="scss">
//轮播图
.swiper-wrap{
	width:100%;
	overflow: hidden;
}
.special-divider{
	padding-top:20px;
	height:80px;
	.special-container-title{
		height:60px;
		// width:1200px;
		margin:10px auto;
		background-color:transparent;
		text-align: center;
		font-size:24px;
		line-height: 60px;
		color:$theme-color;
	}
}
.course-container{
	width:1200px;
	margin:10px auto;
	// background-color:tomato;

	.special-container{
		margin:20px 0px;
		margin-top:0px;
		width:1200px;
		height:400px;
		display:flex;
		flex-direction: row;
		.special-title{
			height:400px;
			width:150px;
			background-color:$theme-color;
			h3{
				margin:20px 0px;
				height:30px;
				line-height:30px;
				font-size:20px;
				color:#eee;
				text-align: center;
				font-weight: bold;
			}
			ul{
				height:400px;
				width:150px;
				// background-color:hotpink;
				li{
					width:150px;
					text-align: center;
					height:30px;
					line-height:30px;
					a{
						color:#eee;
						// font-size:16px;
						font-weight:bold;
						&:hover{
							color:#666;
						}
					}
				}
			}
		}
		.special-cover-container{
			margin-left:20px;
			height:400px;
			width:330px;
			overflow: hidden;
			.special-cover{
				height:400px;
				width:330px;
				background-position: center 30%;
				background-repeat: no-repeat;
				background-size: cover;
				transition-property: transform;
				transition-duration: 1s;
				&:hover{
					transform:scale(1.1)
				}
			}
		}
		
		.special-first-combo{
			height:400px;
			width:330px;
			margin-left:20px;
			background-color:gray;
		}
		.special-combo-list{
			width:680px;
			height:400px;
			margin-left:20px;
			// background-color:greenyellow;
			.combo-container{
				cursor:pointer;
				height:195px;
				// background-color:greenyellow;
				.combo-cover{
					width:100%;
					overflow: hidden;
					img{
						transition-duration: 1s;
						transition-property: transform;
						&:hover{
							transform:scale(1.1);
						}
					}
				}
				.combo-title{
					height:40px;
					// background-color:#F8A23D;
					line-height: 20px;
				}
				.combo-additional{
					height:35px;
					display:flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					.combo-price{
						color:$price-color;
					}
					.buy-combo{
						color:#666;
					}
				}
			}
			.margin-top-20{
				margin-top:10px;
			}
		}
	}
	// .special-container:nth-child(2) .special-title{
	// 	background-color:#F8A23D;
	// }
	// .special-container:nth-child(4) .special-title{
	// 	background-color:#3292CA;
	// }
	// .special-container:nth-child(6) .special-title{
	// 	background-color:#258247;
	// }
	// .special-container:nth-child(8) .special-title{
	// 	background-color:#F55555;
	// }
	// .special-container:nth-child(10) .special-title{
	// 	background-color:#C449FE;
	// }
}
.exam-container{

	width:1200px;
	margin:20px auto;
	.exam-card{
		cursor:pointer;
		margin-bottom:10px;
		img{
			width:100%;
		}
		.exam-title{
			height:60px;
		}
		.exam-price{
			color:$price-color;
			display:flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
}
</style>
<style lang="scss">
//swiper的pagination
.swiper-wrap .swiper-pagination-bullet{
	width: 30px;
  height: 5px;
	border-radius: 2px;
	background-color:#eee;
	opacity:1;
}
.swiper-wrap .swiper-pagination-bullet-active {
	background-color: $theme-color;
}
.course-container .el-card__body{
	border-radius: 0px;
	margin-bottom: 0px;
}
</style>